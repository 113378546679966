import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ bgColor: string; allImgCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  border: 1px solid #dbe3eb;
  border-radius: 10px;
  margin-right: 2rem;
  ${(props) => props.bgColor && `background-color: ${props.bgColor}`};
  :nth-child(3) {
    img {
      align-self: ${(props) => (props.allImgCentered ? `initital` : `center`)};
    }
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Header = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 10rem;
  background: linear-gradient(81.7deg, #e7edf3 8.96%, #e4e6f0 104.78%);
`;

const Header2 = styled(Header)`
  justify-content: flex-start;
  align-items: flex-end;
`;

const Content = styled.div`
  padding: 1rem;
  min-height: 10rem;
`;

const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;

const Image = styled.img`
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

interface HowItWorksProps {
  title: string;
  text: string;
  image: string;
  allImgCentered?: boolean;
  imageAlignBottomLeft?: boolean;
  backgroundColor?: string;
}

const HowItWorksCard: React.FunctionComponent<HowItWorksProps> = ({
  title,
  text,
  image,
  allImgCentered,
  imageAlignBottomLeft,
  backgroundColor,
}) => (
  <Wrapper bgColor={backgroundColor} allImgCentered={allImgCentered}>
    {imageAlignBottomLeft ? (
      <Header2>
        <Image src={image} />
      </Header2>
    ) : (
      <Header>
        <Image src={image} />
      </Header>
    )}

    <Content>
      <Title>{title}</Title>
      <P>{text}</P>
    </Content>
  </Wrapper>
);

export default HowItWorksCard;
