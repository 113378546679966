import React from 'react';
import styled from 'styled-components';
import { Body4Bold, P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ margin: string }>`
  ${(props) => props.margin && `margin: ${props.margin}`};
  display: flex;
  flex-basis: 25%;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    box-shadow: 0px 4px 30px rgba(194, 206, 219, 0.6);
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 7rem;
`;

const Label = styled(Body4Bold)`
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

const Img = styled.img`
  margin-right: 1rem;
`;

const Title = styled(Subtitle5)`
  margin-bottom: 1rem;
`;

interface UseCaseCardProps {
  image?: string;
  label?: string;
  title?: string;
  text?: string;
  margin?: string;
}

const UseCaseCard: React.FunctionComponent<UseCaseCardProps> = ({
  image,
  label,
  title,
  text,
  margin,
}) => (
  <Wrapper margin={margin}>
    <div>
      <Img src={image} />
    </div>
    <Header>
      <Label>{label}</Label>
      {title && <Title>{title}</Title>}
      <P>{text}</P>
    </Header>
  </Wrapper>
);

export default UseCaseCard;
