import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ flexBasis?: string; marginBottom?: string }>`
  flex-basis: ${({ flexBasis }) => (flexBasis ? `${flexBasis}` : `30%`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  margin-right: 1rem;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  border: 1px solid #dbe3eb;
  cursor: pointer;
  transition: 0.4s;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    box-shadow: 0px 4px 30px rgba(194, 206, 219, 0.6);
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;
const Img = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
`;
const Info = styled.div`
  min-height: 10rem;
`;
const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;
interface SolutionComponentProps {
  title: string;
  text: string;
  cardImgSrc: string;
  flexBasis?: string;
  marginBottom?: string;
}
const CardWithIcon: React.FunctionComponent<SolutionComponentProps> = ({
  title,
  text,
  cardImgSrc,
  flexBasis,
  marginBottom,
}) => (
  <Wrapper flexBasis={flexBasis} marginBottom={marginBottom}>
    <Img src={cardImgSrc} alt="card logo" />
    <Info>
      <Title>{title}</Title>
      <P>{text}</P>
    </Info>
  </Wrapper>
);

export default CardWithIcon;
